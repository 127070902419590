/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import Icon from "@mui/material/Icon";
import PropTypes from 'prop-types';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import PDFGenerator from "../../tools/PDFGenerator";
import { selectStyles } from "../../tools/utils";

import Servicios from '../tables/Servicios';

import { Get, Put, PostMulti } from "../../tools/Helper";
import Message from "../../tools/Message";

function OssForm({ data, setShow, getLista, show }) {

  const animatedComponents = makeAnimated();
  const scrollRef = useRef(React.createRef());
  const [inputKey, setInputKey] = useState();

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth() + 1}-${zeroDate}${today.getDate()}`;

  const [formData, setFormData] = useState({
    numero: '',
    num_cotizacion: '',
    fecha_oss: datestring,
    elabora: '',
    elabora_puesto: '',
    acepta: '',
    responsables: undefined,
    info_adicional: '',
    comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
    direccion: '',
    estado: '',
    municipio: '',
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [serviciosArray, setServiciosArray] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [responsablesRegion, setResponsablesRegion] = useState([]);
  const [responsablesCliente, setResponsablesCliente] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [requestData, setRequestData] = useState();

  const [catalogosJson, setCatalogosJson] = useState("");

  const getDataCatalogos = async e => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Get('catalogos');

    if (request.status === "success") {

      setServicios(request.servicios);
      setClientes(request.clientes);
      setResponsables(request.responsables);
      setCatalogosJson(JSON.stringify(request));
    } else {
      setStatus(request);
    }

    setLoading(false);

  }

  const getRegionsByCliente = async (idCliente) => {

    setStatus({ status: null, message: "" });    
    setRegiones([]);

    const request = await Get(`getRegionsByCliente/${idCliente}`);
    
    if (request.status === "success") {
      setRegiones(request.data);
      setStatus({ status: request.status, message: "Regiones cargadas exitosamente." });

      setTimeout(() => {
        setStatus({ status: null, message: "" });
      }, 2000);
    }
    
  }

  const getResponsablesByRegion = async (idRegion) => {

    setStatus({ status: null, message: "" });    
    setResponsablesRegion([]);
    setResponsablesCliente([]);

    const request = await Get(`getResponsablesByRegion/${idRegion}`);

    if (request.status === "success") {
      setResponsablesRegion(request.data);
      setStatus({ status: request.status, message: "Responsables cargados exitosamente." });
    }

  }

  useEffect(async () => {

    console.log('data: ', data);

    setFormData(data);

    await getDataCatalogos();

    if (data.id_region > 0) {
      await getRegionsByCliente(data.cliente.id_cliente);
      await getResponsablesByRegion(data.id_region);

      setResponsablesCliente(data.responsables_clientes);
    }

    if (data.servicios) {
      setServiciosArray(data.servicios);
    }

    setStatus({ status: null, message: "" });

  }, [data, setFormData]);

  const setServArr = (d) => {
    setServiciosArray(d);
  }

  const handleChange = (e, name = "", value = "") => {

    if (name === 'cliente') {

      const infoCliente = clientes.filter(item => item.id_cliente === parseInt(e[value], 10))[0];
      
      setFormData({
        ...formData,
        [value]: e[value],
        [name]: e,
        cliente: infoCliente,
        acepta: infoCliente.persona_contacto,
      });

      getRegionsByCliente(parseInt(e[value], 10));
      return;

    }

    if (name === 'id_region') {

      const infoRegion = regiones.find(option => option.id_region === e[name]);  

      setFormData({
        ...formData,
        id_region: e[value],
        region: infoRegion,
      });

      getResponsablesByRegion(parseInt(e[value], 10));

      return;
    }

    if (name === 'responsablesCliente') {

      setResponsablesCliente(e);

      setFormData({
        ...formData,
        responsables_cliente: e
      });

      return;
    }

    if (name === "") {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      setFormData({ ...formData, [value]: e[value], [name]: e });
    }
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const submitOss = async () => {
    const tempData = formData;
    tempData.servicios = serviciosArray;

    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Put(`oss/${formData.id_oss}`, tempData);

    if (request.status === "success") {
      setStatus({ status: request.status, message: "Se ha guardado la información correctamente." });
      setLoading(false);    
      getLista();      
    }

    setStatus(request);    
    setLoading(false);
    setShow(true);
  }

  const enviarEmails = async () => {
    
    setLoading(true);

    const doc = <PDFGenerator data={formData} />;
    const asPdf = pdf();
    asPdf.updateContainer(doc);
    const blob = await asPdf.toBlob();
    const formData2 = new FormData();
    formData2.append("file", blob);
    formData2.append("id_oss", formData.id_oss);

    const request2 = await PostMulti('email', formData2);

    setShow(true)    
    setLoading(false);

  }
  
  const getRegion = regiones.find(option => option.id_region === formData.id_region);

  return (
    <MDBox pb={3}>
      <Card>
        <MDBox
          p={3}
          className="headerFormPymn"
        >
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <MDTypography variant="h4" display="inline" mr="auto" color="white">
                Editar OSS N° {formData.id_oss}
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox pt={3}>
          <MDBox pb={3} px={3}>

            {loading ?

              <MDBox display="block" textAlign="center" p={5}>
                <CircularProgress size="10rem" />
                <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
              </MDBox>
              :
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Message status={status} />
                  </Grid>
                  <Grid item xs={4} mt="auto">
                    <MDBox>
                      <MDInput type="text" label="No. Cotización" name="num_cotizacion"
                        error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                        value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                    </MDBox>
                  </Grid>
                  <Grid item xs={4} mt="auto">
                    <MDBox>
                      <MDInput type="date" label="Fecha" name="fecha_oss"
                        error={verifyError("fecha_oss")} errorMsg={getError("fecha_oss")}
                        value={formData.fecha_oss} onChange={handleChange} fullWidth />
                    </MDBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MDBox>
                      <MDInput type="text" label="Comentario" name="comentario"
                        error={verifyError("comentario")} errorMsg={getError("comentario")}
                        value={formData.comentario} onChange={handleChange}
                        multiline rows={5} fullWidth />
                    </MDBox>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDBox mb={2}>
                      <MDTypography variant="h5" pb={1}>
                        Cliente
                      </MDTypography>
                      <small className="font-red">{getError("cliente")}</small>
                      {
                        parseInt(formData.status_cotizacion, 10) === 0 ?
                          <Select className={`z-10 ${verifyError("cliente") ? "border-red" : ""}`}
                            options={clientes} placeholder="Cliente"
                            value={formData.cliente} onChange={(e) => handleChange(e, "cliente", "id_cliente")}
                            getOptionValue={(option) => `${option.id_cliente}`}
                            getOptionLabel={(option) => `${option.nombre_comercial}`}
                            menuPortalTarget={document.body} styles={selectStyles}
                          />
                          :
                          <MDTypography variant="p" fontSize={15} pb={1}>
                            {formData.cliente_nombre}
                          </MDTypography>
                      }
                    </MDBox>
                  </Grid>

                  {(regiones.length > 0) ?
                    <>
                      <Grid item xs={12} md={6} mt="auto">
                        <Select
                          className={`z-101 ${verifyError("id_region") ? "border-red" : ""}`}
                          options={regiones} placeholder="Región"
                          value={formData.region || ''}
                          onChange={(e) => handleChange(e, "id_region", "id_region")}
                          getOptionValue={(option) => `${option.id_region}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} mt="auto">

                        <Select className={`z-10 ${verifyError("responsablesRegion") ? "border-red" : ""}`}
                          placeholder="Responsables de empresa"
                          closeMenuOnSelect
                          components={animatedComponents}
                          key={inputKey || ''}
                          isMulti
                          value={responsablesCliente} onChange={(e) => handleChange(e, "responsablesCliente", "respArray")}
                          options={responsablesRegion}
                          getOptionValue={(option) => `${option.id}`}
                          getOptionLabel={(option) => `${option.nombre}`}
                        />

                      </Grid>
                    </>
                    : null
                  }

                </Grid>
                <Divider />
                <Servicios serviciosArray={serviciosArray} setServiciosArray={setServArr}
                  catalogoServicios={servicios} errors={"errors" in status ? status.errors : null}
                  setStatus={setStatus} typeForm="oss" editOss={parseInt(formData.status_cotizacion, 10) === 0 ? 'true' : 'false'} />
                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <MDInput type="text" label="Información Adicional" name="info_adicional"
                      error={verifyError("info_adicional")} errorMsg={getError("info_adicional")}
                      value={formData.info_adicional} onChange={handleChange}
                      multiline rows={5} fullWidth disabled={parseInt(formData.status_cotizacion, 10) === 0 ? false : 'true'} />
                  </MDBox>
                </Grid>
                <Divider />

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDBox mb={2}>
                      <MDTypography variant="h5" pb={1}>
                        Responsables OSS
                      </MDTypography>
                      <Select className={`z-10 ${verifyError("responsables") ? "border-red" : ""}`}
                        closeMenuOnSelect
                        placeholder="Responsables"
                        components={animatedComponents}
                        isMulti
                        value={formData.responsables} onChange={(e) => handleChange(e, "responsables", "respArray")}
                        options={responsables}
                        getOptionValue={(option) => `${option.id_responsable}`}
                        getOptionLabel={(option) => `${option.nombre} - ${option.puesto}`}
                      />
                      <small className="font-red">{getError("responsables")}</small>
                    </MDBox>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <MDBox>
                      <MDTypography variant="h5" pb={1}>
                        Firma de Autorizacion
                      </MDTypography>
                      <MDInput
                        type="text"
                        label="Nombre de quien elabora"
                        name="elabora"
                        error={verifyError("elabora")}
                        errorMsg={getError("elabora")}
                        value={formData.elabora}
                        onChange={handleChange} fullWidth
                        inputProps={{ disabled: true, }} />
                    </MDBox>
                  </Grid>
                  <Grid item xs={6} mt="auto">
                    <MDBox>
                      <MDInput
                        type="text"
                        label="Puesto"
                        name="elabora_puesto"
                        error={verifyError("elabora_puesto")}
                        errorMsg={getError("elabora_puesto")}
                        value={formData.elabora_puesto}
                        inputProps={{ disabled: true, }}
                        onChange={handleChange} fullWidth />
                    </MDBox>
                  </Grid>

                  {/* <Grid item xs={6}>
                    <MDBox>
                      <MDInput type="text" label="Nombre de quien acepta" name="acepta"
                        error={verifyError("acepta")} errorMsg={getError("acepta")}
                        value={formData.acepta} onChange={handleChange} fullWidth />
                    </MDBox>
                  </Grid> */}

                </Grid>

                <Grid container mt={1} spacing={2}>

                  <Grid item xs={6} md={3}>
                    &nbsp;
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <MDButton
                      disabled={loading}
                      className="btnPaymun"
                      onClick={() => submitOss()} fullWidth>
                      <Icon fontSize="small">done</Icon>
                      &nbsp;Guardar
                    </MDButton>
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <MDButton
                      className="btnPaymun btnBlue"
                      disabled={loading}
                      onClick={() => enviarEmails()}
                      fullWidth>
                      <Icon fontSize="small">mail_outline</Icon>
                      &nbsp;Enviar
                    </MDButton>
                  </Grid>

                  <Grid item xs={6} md={2}>
                    <MDButton
                      className="btnBackPaymun"
                      fullWidth
                      onClick={() => setShow(true)}>
                      <Icon fontSize="small">arrow_back</Icon>
                      &nbsp;Regresar
                    </MDButton>
                  </Grid>

                </Grid>
              </>
            }

          </MDBox>
        </MDBox>
      </Card>
    </MDBox>

  );
}

OssForm.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired,
  getLista: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default OssForm;