/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import DataTableExtensions from "react-data-table-component-extensions";
import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";

import Modal from "react-modal";
import { Get, Del } from "../../tools/Helper";
import RegionForm from "./RegionForm";


function Regiones() {
  const { idEmpresa } = useParams();
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      maxWidth: "600px",
    },
  };

  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, nombre: "", activo: 1 });
  const [empresa, setEmpresa] = useState({ id_cliente: 0, nombreEmpresa: "" });
  const [totalRows, setTotalRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const btnCancel = useRef(null);
  const [showList, setShowList] = useState(true);
  const [dataSearch, setdataSearch] = useState([]);


  const emptyForm = {
    clave: "",
    nombre: "",
    id_region: 0,
    id_cliente: idEmpresa,
    cliente: "",
    empresa
  };

  const [selectedData, setSelectedData] = useState(emptyForm);

  const editarButton = (row) => {

    console.log("editar button");

    console.log(row);

    setSelectedData(row);
    setShow(false);
    setEditar(true);
  };


  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  };

  const getData = async (page, rowsPerPage1) => {
    setStatus({ status: null, message: "" });
    setLoading(true);

    let request;
    if (page) {
      request = await Get(`getListPaginateRegiones?page=${page}&per_page=${rowsPerPage1}&delay=1&idEmpresa=${idEmpresa}`);
    }
    else {
      request = await Get(`getListPaginateRegiones?page=${currentPage}&per_page=${rowsPerPage}&delay=1&idEmpresa=${idEmpresa}`);
    }

    // const request = await Get(`getRegionsByCliente/${idEmpresa}`);
    setEmpresa(request.cliente);

    if (request.status === "success") {
      setData(request.data.data);

      setTotalRows(request.data.total);

      setStatus({ status: request.status, message: "Información cargada exitosamente." });
    } else {
      setStatus({ status: request.status, message: "Error." });
    }

    setSelectedData(emptyForm);
    setLoading(false);
  };

  useEffect(() => {
    getData();
    console.log(idEmpresa);
  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(row) {
    console.log(row);

    setIsOpen(true);
    setSelectedDelete({ id: row.id_region, nombre: row.nombre, deleted_at: row.deleted_at });
  }

  const deleteRegion = async () => {
    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);

    const request = await Del(`region/${selectedDelete.id}`);

    if (request.status === "success") {
      await getData();
    }

    setStatus(request);
    setShow(true);
    setLoading(false);
  };

  const editButton = (row) => (
    <Tooltip title="Editar">
      <MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}>
        <Icon>modeEdit</Icon>
      </MDButton>
    </Tooltip>
  );
  const deleteButton = (row) => (
    <Tooltip title="Desactivar">
      <MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModal(row)}>
        <Icon>not_interested</Icon>
      </MDButton>
    </Tooltip>
  );
  const activateButton = (row) => (
    <Tooltip title="Activar">
      <MDButton className="btnPaymun btnChangeStatusOn" iconOnly onClick={() => openModal(row)}>
        <Icon>check_box</Icon>
      </MDButton>
    </Tooltip>
  );

  const backEmpresas = () => {
    navigate(`/cartera-clientes`);
  };

  const columns = [
    {
      name: "Clave",
      selector: (row) => row.clave,
      compact: true,
      grow: 1,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      wrap: true,
      grow: 3,
    },
    {
      name: "",
      selector: (row) => editButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: "",
      selector: (row) => (row.deleted_at == null ? deleteButton(row) : activateButton(row)),
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const columnsSucursales = [
    {
      name: 'Clave',
      selector: row => row.clave,
      compact: true,
      grow: 0,
    },
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle_numero,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
      compact: true,
      grow: 0,
    }
  ];

  const expandedComponent = (infoRow) => {

    console.log(infoRow.data.sucursales);

    return <MDBox m={2}  >

      <DataTable
        className="bgkSuc"
        columns={columnsSucursales}
        data={infoRow.data.sucursales}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Sucursales por página:',
          rangeSeparatorText: 'de',
          noRowsPerPage: false,
          selectAllRowsItem: true,
          selectAllRowsItemText: 'Todos'
        }}
        noDataComponent={<MDBox py={5}>No se han encontrado sucursales.</MDBox>}
      /></MDBox>;

  }

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const searchRegion = async e => {

    if (!search) {
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);
    setdataSearch([]);

    const request = await Get(`searchRegiones/${search}?idEmpresa=${idEmpresa}`);

    if (request.status === "success") {
      setdataSearch(request.data);
      setActiveSearch(true);
      setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
      btnCancel.current?.focus();
    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      searchRegion();
    }
  };

  const tableData = {
    columns,
    data,
  };

  const buscarButton = () => {
    searchRegion();
  }

  const clearSearch = () => {
    setSearch('');
    setActiveSearch(false);
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3}>
        <Fade in={show} timeout={800}>

          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox p={3} className="headerFormPymn">
                <Grid container spacing={6}>
                  <Grid item xs={6} >
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Regiones
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd mr10" onClick={() => backEmpresas()}>
                      {" "}
                      <Icon fontSize="small">account_box</Icon> &nbsp;Empresas{" "}
                    </MDButton>
                    <MDButton className="btnPaymun btnAdd" onClick={() => crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? (
                    <MDBox display="block" fullWidth textAlign="center" p={5}>
                      <CircularProgress size="10rem" />
                      <MDTypography variant="h4" pt={3}>
                        Cargando...
                      </MDTypography>
                    </MDBox>
                  ) : (
                    <>

                      <MDBox pt={3} px={3}>
                        <Grid container>

                          <Grid item xs={2}>

                            <MDInput type="text" label="Buscar" name="buscar_oss"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                              onKeyPress={handleKeyPress}
                              variant="standard"
                              disabled={activeSearch}
                              fullWidth
                            />
                          </Grid>

                          {!activeSearch ?
                            <Grid item xs={1} px={1} pt={.3}>
                              <MDButton variant="gradient" className="btnPaymun btnSearch" iconOnly size="medium" onClick={() => buscarButton()}>
                                <Icon>search</Icon>
                              </MDButton>
                            </Grid>
                            :
                            <Grid item xs={1} px={1} pt={.3}>
                              <MDButton variant="gradient" className="btnPaymun btnSearch btnSearchCancel" iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                                <Icon>cancel</Icon>
                              </MDButton>
                            </Grid>
                          }

                        </Grid>
                      </MDBox >

                      {!activeSearch ?
                        <MDBox>
                          <Grid item xs={12}>
                            <MDTypography variant="h5" display="inline" mr="auto">
                              Empresa: {empresa.nombreEmpresa}
                            </MDTypography>
                          </Grid>

                          <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            paginationPerPage={rowsPerPage}
                            expandableRows
                            onChangePage={handlePageChange}
                            onChangeRowsPerPage={handleRowsPerPageChange}
                            expandableRowsComponent={expandedComponent}
                            paginationComponentOptions={{
                              rowsPerPageText: "Regiones por página:",
                              rangeSeparatorText: "de",
                              noRowsPerPage: false,
                              selectAllRowsItem: true,
                              selectAllRowsItemText: "Todos",
                            }}
                            noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                          />
                        </MDBox>
                        :
                        <MDBox>
                          <Grid item xs={12}>
                            <MDTypography variant="h5" display="inline" mr="auto">
                              Empresa: {empresa.nombreEmpresa}
                            </MDTypography>
                          </Grid>

                          <DataTable
                            pagination
                            columns={columns}
                            data={dataSearch}
                            progressPending={loading}
                            expandableRows
                            expandableRowsComponent={expandedComponent}
                            paginationComponentOptions={{
                              rowsPerPageText: "Regiones por página:",
                              rangeSeparatorText: "de",
                              noRowsPerPage: false,
                              selectAllRowsItem: true,
                              selectAllRowsItemText: "Todos",
                            }}
                            noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                          />
                        </MDBox>
                      }
                    </>
                  )}
                </MDBox>
              </MDBox>
            </Card>
          </Collapse>
        </Fade>

        <Grid container xs={12} md={12}>
          <Grid item pb={3} xs={12}>
            <Fade in={!show} timeout={800}>
              <Collapse in={!show} timeout={50}>
                <RegionForm
                  data={selectedData}
                  editar={editar}
                  setShow={setShow}
                  empresa={empresa}
                  getLista={getData}
                />
              </Collapse>
            </Fade>
          </Grid>
        </Grid>

        <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea ${selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
            } la region ${selectedDelete ? selectedDelete.nombre : ""}?`}
          style={customStyles}
        >
          <Grid container p={1}>
            <Grid item md={12} sm={12}>
              <h2 className="alignCenter txtInfoModal">{`¿Seguro que desea ${selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
                } la region ${selectedDelete ? selectedDelete.nombre : ""}?`}</h2>
            </Grid>
          </Grid>

          <Grid container spacing={2} p={1}>
            <Grid item md={6} sm={12} xs={12} className="alignRigth">
              <MDButton className="btnPaymun" disabled={loading} onClick={() => deleteRegion()}>
                <Icon fontSize="small">done</Icon>
                &nbsp;{selectedDelete && selectedDelete.deleted_at ? "Activar" : "Desactivar"}
              </MDButton>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="alignLeft">
              <MDButton className="btnBackPaymun" disabled={loading} onClick={() => closeModal()}>
                <Icon fontSize="small">arrow_back</Icon>
                &nbsp;Cancelar
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

// Typechecking props for the ProfileInfoCard
Regiones.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired,
};

export default Regiones;
