
export function getDateLarge(date) {
    const newDate = new Date(`${date}T00:00:00-06:00`);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	return newDate.toLocaleDateString('es-MX', options)
}

export function getHoursLarge(datetime) {
    const newDatetime = new Date(datetime);
	return newDatetime.getHours('es-MX')
}

export function parseCurrency(value) {
        
    let newValue = value ?? 0;

    if(value){
        if((value).toString() === 'NaN'){
            newValue = 0;
        }
    }
    const formatCurrency = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,      
        roundingMode: 'floor'  
      });
          
    return formatCurrency.format(newValue);
}

export function getDateNow() {
    const today = new Date();
    const zeroDate = today.getDate() < 9 ? "0" : "";
    const zeroMonth = today.getMonth() < 9 ? "0" : "";
    return `${today.getFullYear()}-${zeroMonth}${today.getMonth()+1}-${zeroDate}${today.getDate()}`;
}

export function getDateNowDDMMYYYY() {
    const today = new Date();
    const zeroDate = today.getDate() < 9 ? "0" : "";
    const zeroMonth = today.getMonth() < 9 ? "0" : "";
    return `${zeroDate}${today.getDate()}/${zeroMonth}${today.getMonth()+1}/${today.getFullYear()}`;
}

export function getDateNowWithoutSeparator() {
    const today = new Date();
    const zeroDate = today.getDate() < 9 ? "0" : "";
    const zeroMonth = today.getMonth() < 9 ? "0" : "";
    return `${zeroDate}${today.getDate()}${zeroMonth}${today.getMonth()+1}${today.getFullYear()}`;
}

export function getRandomString(longitud) {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
  
    for (let i = 0; i < longitud; i+= 1) {
      const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
      id += caracteres.charAt(indiceAleatorio);
    }
  
    return id;
}

export const onlyNumbers = (e) => {
        
    // no permite los caracteres
    // - y +
    if (e.charCode === 45 ||
        e.charCode === 43 ||
        e.charCode === 45
    ) {
        e.preventDefault();
    }

    // si es cero y no hay informacion
    // retorna el evento
    if (e.charCode === 48 && e.target.value.length === 0) {
        e.preventDefault();
    }
};

export const floorFigure = (figure, decimals) => {
    // if (!decimals) decimals = 2;
    const pow = 10 ** decimals
    return Math.trunc(figure * pow) / pow;
}

export const selectStyles = {
    menuPortal: base => ({ ...base, zIndex: 9999, fontSize: "0.75em" }),
    menu: provided => ({ ...provided, zIndex: "9999 !important" })
  };

export const tileLayer = {
    attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
}

export const getTitlePopUpMarker = (infoSuc) => `${infoSuc.nombre}, Tel. ${infoSuc.telefono}, ${infoSuc.region}`;

export const ubicacionDefault = [18.265474408038845, -93.22504341064321]; 
