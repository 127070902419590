import axios from 'axios';

 // const base = 'http://localhost:8000/api/';

// const base = 'https://www.paymun.mx/back_prevengo/public/api/';
// const base = 'https://backprevengo.paymun.mx/public/api/';
const base = 'https://backprev.paymun.mx/public/api/'

export async function Post(path, obj) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'application/json'
	}

	let data = "";

	await axios.post((base + path), obj, {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;

			if (data.status) {
				if (data.status === "noauth") {
					localStorage.clear();
					// navigate("/login");
				}
			}
		})

	return data;
}

export async function PostMulti(path, obj) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'multipart/form-data'
	}

	let data = "";

	await axios.post((base + path), obj, {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;

			if (data.status) {
				if (data.status === "noauth") {
					localStorage.clear();
					// navigate("/login");
				}
			}
		})

	return data;
}

export async function Put(path, obj) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'application/json'
	}

	let data = "";

	await axios.put((base + path), obj, {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;

			if (data.status) {
				if (data.status === "noauth") {
					localStorage.clear();
					// navigate("/login");
				}
			}
		})

	return data;
}

export async function Get(path) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'application/json'
	}
	let data = "";

	await axios.get((base + path), {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;

			if (data.status) {
				if (data.status === "noauth") {
					localStorage.clear();
					// navigate("/login");
				}
			}
		})

	return data;
}

export async function Del(path) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Authorization': `Bearer ${token}`,
		'Content-Type': 'application/json'
	}

	let data = "";

	await axios.delete((base + path), {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;

			if (data.status) {
				if (data.status === "noauth") {
					localStorage.clear();
					// navigate("/login");
				}
			}
		})

	return data;
}

export async function GetGuest(path) {

	const head = {
		'Content-Type': 'application/json'
	}

	let data = "";

	await axios.get((base + path), {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;
		})

	return data;
}

export async function PostGuest(path, obj) {

	const token = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).token : '';

	const head = {
		'Content-Type': 'application/json'
	}

	let data = "";

	await axios.post((base + path), obj, {
		headers: head
	})
		.then((response) => {
			data = response.data;
		})
		.catch((error) => {
			data = error.response.data;
		})

	return data;
}
