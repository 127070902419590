/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as React from 'react';
import { useState, useLayoutEffect, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

import { useParams, useNavigate } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";

import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';

import Modal from 'react-modal';
import Icon from "@mui/material/Icon";
import { Button, ButtonGroup } from '@mui/material';
import { Place, TableRows } from '@mui/icons-material';

import SucursalForm from "./SucursalForm";
import SucursalesMap from "./SucursalesMap";
import { Get, Del } from "../../tools/Helper";
import { getTitlePopUpMarker, ubicacionDefault } from "../../tools/utils"

function Sucursales() {

  const emptyForm = {
    clave: '',
    nombre: '',
    calle_numero: '',
    codigo_postal: '',
    estado: '',
    municipio: '',
    region: '',
    status: '',
    id_cliente: 0,
    id_colonia: 0,
    id_region: 0,
    latitud: '',
    longitud: '',
    ubicacion: ubicacionDefault,
    coordenadas: ubicacionDefault
  };

  const conditionalRowStyles = [
    {
      when: row => row.deleted_at,
      style: row => ({ backgroundColor: '#E6EBED' }),
    },
  ];

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const { idEmpresa } = useParams();

  const [selectedDelete, setSelectedDelete] = useState({ id: 0, name: "", deleted_at: null });
  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const btnCancel = useRef(null);
  const [totalRows, setTotalRows] = useState(10);
  const [dataSearch, setdataSearch] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cliente, setCliente] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [data, setData] = useState([]);
  const [dataMap, setDataMap] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editar, setEditar] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [estiloShowMap, setEstiloShowMap] = useState('btnPaymun btnNotActive notRoundRight');
  const [estiloShowTable, setEstiloShowTable] = useState('btnPaymun notRoundLeft');
  const [showList, setShowList] = useState(true);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const editarButton = (row) => {
    setEditar(true);
    setSelectedData(row);
    setShow(false);
  }

  const searchSucursal = async e => {

    if (!search) {
      return;
    }

    setStatus({ status: null, message: "" });
    setLoading(true);
    setdataSearch([]);

    const request = await Get(`searchSucursales/${search}?idEmpresa=${idEmpresa}`);

    if (request.status === "success") {
      setdataSearch(request.data);
      setActiveSearch(true);
      setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
      btnCancel.current?.focus();

    }
    else {
      setStatus({ status: request.status, message: "Error." });
    }

    setLoading(false);
  }

  const getData = async (page, rowsPerPage1) => {

    setStatus({ status: null, message: "" });
    setLoading(true);

    if (activeSearch) {
      searchSucursal();
    } else {
      let request;

      if (page) {
        request = await Get(`getListPaginateSucursales?page=${page}&per_page=${rowsPerPage1}&delay=1&idEmpresa=${idEmpresa}`);
      }
      else {
        request = await Get(`getListPaginateSucursales?page=${currentPage}&per_page=${rowsPerPage}&delay=1&idEmpresa=${idEmpresa}`);
      }

      if (request.status === "success") {

        const arrSucursales = request.data.data.map((item) =>  {
          const sucursal = { ...item };

          if (sucursal.ubicacion) {
            sucursal.coordenadas = [parseFloat(sucursal.latitud), parseFloat(sucursal.longitud)];
          }

          return sucursal          
        });

        setData(arrSucursales);
        setTotalRows(request.data.total);
        setStatus({ status: request.status, message: "Se ha creado exitosamente." });
      } else {
        setStatus({ status: request.status, message: "Error." });
      }

      setRegiones(request.regiones);
      setCliente(request.empresa[0]);
    }
    setSelectedData(emptyForm);
    setLoading(false);
  }

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete({ id: 0, name: "", deleted_at: null });
  }

  const deleteUser = async () => {
    closeModal();

    setStatus({ status: null, message: "" });
    setLoading(true);

    console.log(selectedDelete);

    const request = await Del(`sucursales/${selectedDelete.id}`);

    if (request.status === "success") {
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);

  const backEmpresas = () => {
    navigate(`/cartera-clientes`);
  }

  function openModal(row) {
    setSelectedDelete({ id: row.id_sucursal, nombre: row.nombre, deleted_at: row.deleted_at });
    setIsOpen(true);
  }

  const editButton = (row) => <Tooltip title="Editar"><MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}><Icon>modeEdit</Icon></MDButton></Tooltip>;
  const deleteButton = (row) => <Tooltip title="Desactivar"><MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModal(row)}><Icon>not_interested</Icon></MDButton></Tooltip>;
  const activateButton = (row) => <Tooltip title="Activar"><MDButton className="btnPaymun btnChangeStatusOn" iconOnly onClick={() => openModal(row)}><Icon>check_box</Icon></MDButton></Tooltip>;

  const columns = [
    {
      name: 'Clave',
      selector: row => row.clave,
    }, 
    {
      name: 'Región',
      selector: row => row.region,
    },    
    {
      name: 'Nombre',
      selector: row => row.nombre,
    },
    {
      name: 'Calle y número',
      selector: row => row.calle_numero,
    },
    {
      name: 'Colonia',
      selector: row => row.colonia,
    },
    {
      name: 'Municipio',
      selector: row => row.municipio,
    },
    {
      name: 'Estado',
      selector: row => row.estado,
    },
    {
      name: '',
      selector: row => editButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
    {
      name: '',
      selector: row => (row.deleted_at) ? activateButton(row) : deleteButton(row),
      compact: true,
      grow: 0,
      width: "60px",
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const crearButton = () => {

    emptyForm.cliente = cliente;

    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  }

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  useLayoutEffect(() => {    
    getData();
  }, []);

  useEffect(() => {
  }, [showList]);

  const buscarButton = () => {
    searchSucursal();
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      searchSucursal();
    }
  };

  const showTable = () => {
    setEstiloShowMap('btnPaymun notRoundRight btnNotActive');
    setEstiloShowTable('btnPaymun notRoundLeft');
    setShowList(true);
    setDataMap([]);
  }

  const clearSearch = () => {

    setSearch('');
    setActiveSearch(false);

    if (!showList) {
      showTable();      
    }
    
  }  

  const showMap = () => {
    setEstiloShowMap('btnPaymun notRoundRight');
    setEstiloShowTable('btnPaymun notRoundLeft btnNotActive');
    setShowList(false);

    let sucList = [];

    if (activeSearch) {
      sucList = dataSearch.map((infoSuc) => (infoSuc.latitud !== null) ?
      {
          lat: (infoSuc) ? parseFloat(infoSuc.latitud) : '',
          lng: (infoSuc) ? parseFloat(infoSuc.longitud) : '',
          title: (infoSuc) ? getTitlePopUpMarker(infoSuc) : ''
      } : null).filter(v => v);      

      
    } else {
      sucList = data.map((infoSuc) => (infoSuc.latitud !== null) ?
      {
          lat: (infoSuc) ? parseFloat(infoSuc.latitud) : '',
          lng: (infoSuc) ? parseFloat(infoSuc.longitud) : '',
          title: (infoSuc) ? getTitlePopUpMarker(infoSuc) : ''
      } : null).filter(v => v);
      
    }

    setDataMap(sucList);
  }

  return (

    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>

        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={6}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Sucursales
                    </MDTypography>
                  </Grid>
                  <Grid item xs={6} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd mr10"
                      onClick={() => backEmpresas()} >
                      <Icon fontSize="small">account_box</Icon>
                      &nbsp;Empresas
                    </MDButton>
                    <MDButton className="btnPaymun btnAdd"
                      disabled={loading}
                      onClick={() => crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>

              <MDBox pt={3} px={3}>
                <Grid container>

                  <Grid item xs={2}>

                    <MDInput type="text" label="Buscar" name="buscar_oss"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={handleKeyPress}
                      variant="standard"
                      disabled={activeSearch}
                      fullWidth
                    />
                  </Grid>

                  {!activeSearch ?
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton variant="gradient" className="btnPaymun btnSearch" iconOnly size="medium" onClick={() => buscarButton()}>
                        <Icon>search</Icon>
                      </MDButton>
                    </Grid>
                    :
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton variant="gradient" className="btnPaymun btnSearch btnSearchCancel" iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                        <Icon>cancel</Icon>
                      </MDButton>
                    </Grid>
                  }

                  <Grid item xs={9} textAlign="right" >
                    <ButtonGroup>

                      <Button
                        className={estiloShowTable}
                        variant='contained'
                        onClick={showTable}
                        size="small"
                        startIcon={<TableRows />}>
                        Lista
                      </Button>

                      <Button
                        className={estiloShowMap}
                        variant='contained'
                        onClick={showMap}
                        size="small"
                        startIcon={<Place />}>
                        Mapa
                      </Button>

                    </ButtonGroup>
                  </Grid>
                </Grid>
              </MDBox >

              {!showList ?
                <MDBox pt={3} px={3}>
                  <Grid container>
                    <Grid item xs={12} height={430}>                                            
                      <SucursalesMap arrSucursales={dataMap} height={400} />
                    </Grid>
                  </Grid>
                </MDBox> : null
              }

              {showList ?
                !activeSearch ?
                  <MDBox pt={3}>
                    <MDBox px={3}>
                      {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                        <CircularProgress size="10rem" />
                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                      </MDBox>
                        :
                        <>
                          {(cliente !== undefined) ?

                            <Grid container>
                              <Grid item xs={12} mb={1} >
                                <MDTypography variant="h5" mt={0} pt={0} >Empresa: {cliente.nombre_comercial}</MDTypography>
                              </Grid>
                            </Grid>

                            : null}
                          <Grid item xs={12} textAlign="right" >
                            <DataTable
                              pagination
                              paginationServer
                              columns={columns}
                              data={data}
                              paginationTotalRows={totalRows}
                              paginationDefaultPage={currentPage}
                              paginationPerPage={rowsPerPage}
                              onChangePage={handlePageChange}
                              onChangeRowsPerPage={handleRowsPerPageChange}
                              paginationComponentOptions={{
                                rowsPerPageText: 'Sucursales por página:',
                                rangeSeparatorText: 'de',
                                noRowsPerPage: false,
                                selectAllRowsItem: true,
                                selectAllRowsItemText: 'Todos'
                              }}
                              conditionalRowStyles={conditionalRowStyles}
                              noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}
                            />
                          </Grid>
                        </>
                      }
                    </MDBox>
                  </MDBox>
                  :
                  // Tabla de resultado de busqueda                
                  <MDBox pt={3}>
                    <MDBox px={3}>
                      {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                        <CircularProgress size="10rem" />
                        <MDTypography variant="h4" pt={3}>Buscando...</MDTypography>
                      </MDBox> :

                        <DataTable
                          pagination
                          columns={columns}
                          data={dataSearch}
                          progressPending={loading}
                          paginationComponentOptions={{
                            rowsPerPageText: 'Sucursales por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}

                        />

                      }
                    </MDBox>
                  </MDBox>
                : null
              }

            </Card>
          </Collapse>
        </Fade>

        <Grid container xs={12} md={12}>
          <Grid item pb={3} xs={12}>
            <Fade in={!show} timeout={800}>
              <Collapse in={!show} timeout={50}>
                <SucursalForm arrRegiones={regiones} idEmpresa={idEmpresa} data={selectedData} editar={editar} setShow={setShow} getLista={getData} />
              </Collapse>
            </Fade>
          </Grid>
        </Grid>

        <Modal
          isOpen={modalIsOpen}
          contentLabel={`¿Seguro que desea eliminar la sucursal ${selectedDelete.nombre}?`}
          style={customStyles}
        >

          <Grid container p={1}>
            <Grid item md={12} sm={12}>
              <h2 className='alignCenter txtInfoModal' >¿Seguro que desea {(selectedDelete.deleted_at) ? "reactivar" : "desactivar"} la sucursal {selectedDelete.nombre}?</h2>
            </Grid>
          </Grid>
          <Grid container spacing={2} p={1}>
            <Grid item md={6} sm={12} xs={12} className='alignRigth' >
              <MDButton className="btnPaymun" disabled={loading}
                onClick={() => closeModal()}>
                Cancelar
              </MDButton>
            </Grid>
            <Grid item md={6} sm={12} xs={12} className="alignLeft">
              <MDButton className="btnBackPaymun" disabled={loading}
                onClick={() => deleteUser()}>
                Cambiar status
              </MDButton>
            </Grid>
          </Grid>
        </Modal>
      </MDBox>
      <Footer />
    </DashboardLayout>

  );
}

export default Sucursales;