import { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
// @mui icons
import Fade from "@mui/material/Fade";
import Collapse from "@mui/material/Collapse";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";
// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import MDButton from "components/MDButton";

import DataTable from "react-data-table-component";
import Icon from "@mui/material/Icon";
import Modal from "react-modal";
import { Get, Del } from "../../tools/Helper";
import ColoniaForm from "./ColoniaForm";

function Colonias() {
  const { idEmpresa } = useParams();
  const navigate = useNavigate();
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      maxWidth: "600px",
    },
  };
  const emptyForm = {
    clave: "",
    nombre: "",
    codigo_postal: "",
    municipio: "",
  };
  const [show, setShow] = useState(true);
  const [editar, setEditar] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyForm);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [selectedDelete, setSelectedDelete] = useState({ id: 0, nombre: "", activo: 1 });

  const [dataSearch, setdataSearch] = useState([]);
  const [activeSearch, setActiveSearch] = useState(false);
  const [totalRows, setTotalRows] = useState(10);  
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const btnCancel = useRef(null);

  const editarButton = (row) => {
    console.log("editar button");
    console.log(row);
    setSelectedData(row);
    setShow(false);
    setEditar(true);
  };

  const crearButton = () => {
    setSelectedData(emptyForm);
    setShow(false);
    setEditar(false);
  };

  const searchColonias = async e => {
  
      if (!search) {
        return;
      }
  
      setStatus({ status: null, message: "" });
      setLoading(true);
      setdataSearch([]);
  
      const request = await Get(`searchColonias/${search}`);
  
      if (request.status === "success") {
        setdataSearch(request.data);
        setActiveSearch(true);
        setStatus({ status: request.status, message: "Se ha realizado la búsqueda exitosamente." });
        btnCancel.current?.focus();
  
      }
      else {
        setStatus({ status: request.status, message: "Error." });
      }
  
      setLoading(false);
    }

  const getData = async (page, rowsPerPage1) => {
   
    setStatus({ status: null, message: "" });
    setLoading(true);

    if (activeSearch) {
      searchColonias();
    } else {
      let request;

      if (page) {
        request = await Get(`getListPaginateColonias?page=${page}&per_page=${rowsPerPage1}&delay=1`);
      }
      else {
        request = await Get(`getListPaginateColonias?page=${currentPage}&per_page=${rowsPerPage}&delay=1`);
      }

      if (request.status === "success" ||
          request.status === "empty") {

        console.log('request.data.data: ', request.data.data);
        setData(request.data.data);
        setColonias(request.colonias);
        setTotalRows(request.data.total);
        setStatus({ status: request.status, message: "Se ha creado exitosamente." });
      } else {
        setStatus({ status: request.status, message: "Error." });
      }
    
    }

    setSelectedData(emptyForm);
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  function closeModal() {
    setIsOpen(false);
    setSelectedDelete(null);
  }

  function openModal(row) {
    console.log(row);
    setIsOpen(true);
    setSelectedDelete({ id: row.colonia_id, nombre: row.colonia_nombre, deleted_at: row.deleted_at });
  }

  const deleteColonia = async () => {
    closeModal();
    setStatus({ status: null, message: "" });
    setLoading(true);
    const request = await Del(`colonia/${selectedDelete.id}`);
    if (request.status === "success") {
      await getData();
    }
    setStatus(request);
    setShow(true);
    setLoading(false);
  };

  const editButton = (row) => (
    <Tooltip title="Editar">
      <MDButton className="btnPaymun" iconOnly onClick={() => editarButton(row)}>
        <Icon>modeEdit</Icon>
      </MDButton>
    </Tooltip>
  );

  const deleteButton = (row) => (
    <Tooltip title="Desactivar">
      <MDButton className="btnPaymun btnChangeStatus" iconOnly onClick={() => openModal(row)}>
        <Icon>not_interested</Icon>
      </MDButton>
    </Tooltip>
  );

  const activateButton = (row) => (
    <Tooltip title="Activar">
      <MDButton className="btnPaymun btnChangeStatusOn" iconOnly onClick={() => openModal(row)}>
        <Icon>check_box</Icon>
      </MDButton>
    </Tooltip>
  );

  const backEmpresas = () => {
    navigate(`/cartera-clientes`);
  };

  const columns = [
    {
      name: "Clave",
      selector: (row) => row.clave,
      compact: true,
      grow: 1,
    },
    {
      name: "Nombre",
      selector: (row) => row.nombre,
      wrap: true,
      grow: 3,
    },
    {
      name: "Código postal",
      selector: (row) => row.codigo_postal,
      wrap: true,
      grow: 3,
    },

    {
      name: "Estado",
      selector: (row) => row.estado.nombre,
      wrap: true,
      grow: 3,
    },
    {
      name: "Municipio",
      selector: (row) => row.municipio.nombre,
      wrap: true,
      grow: 3,
    },
    {
      name: "",
      selector: (row) => editButton(row),
      grow: 0,
      compact: true,
      width: "60px",
    },
    {
      name: "",
      selector: (row) => (row.deleted_at == null ? deleteButton(row) : activateButton(row)),
      grow: 0,
      compact: true,
      width: "60px",
    },
  ];

  const handlePageChange = (page) => {
    getData(page, rowsPerPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    // if (!data.length) return; // when the table is rendered for the first time, this would trigger, and we don't need to call fetchUsers again
    getData(1, newRowsPerPage);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1);
  };

  const buscarButton = () => {
    searchColonias();
  }

  const handleKeyPress = (e) => {

    if (e.key === 'Enter') {
      searchColonias();
    }
  };

  const clearSearch = () => {
    setSearch('');
    setActiveSearch(false);        
  }  

  const tableData = {
    columns,
    data,
  };

  return (
    <Grid container xs={12} md={12}>
      <Grid item pb={3} xs={12}>
        <Fade in={show} timeout={800}>
          <Collapse in={show} timeout={50}>
            <Card>
              <MDBox
                p={3}
                className="headerFormPymn"
              >
                <Grid container spacing={6}>
                  <Grid item xs={9}>
                    <MDTypography variant="h4" display="inline" mr="auto" color="white">
                      Colonias
                    </MDTypography>
                  </Grid>
                  <Grid item xs={3} textAlign="right" >
                    <MDButton className="btnPaymun btnAdd"
                      onClick={() => crearButton()}>
                      <Icon fontSize="small">add</Icon>
                      &nbsp;Agregar
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              
              <MDBox pt={3} px={3}>
                <Grid container>

                  <Grid item xs={2}>

                    <MDInput type="text" label="Buscar" name="buscar_oss"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={handleKeyPress}
                      variant="standard"
                      disabled={activeSearch || loading}
                      fullWidth
                    />
                  </Grid>

                  {!activeSearch ?
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton disabled={loading} variant="gradient" className="btnPaymun btnSearch" iconOnly size="medium" onClick={() => buscarButton()}>
                        <Icon>search</Icon>
                      </MDButton>
                    </Grid>
                    :
                    <Grid item xs={1} px={1} pt={.3}>
                      <MDButton disabled={loading} variant="gradient" className="btnPaymun btnSearch btnSearchCancel" iconOnly size="medium" onClick={() => clearSearch()} ref={btnCancel}>
                        <Icon>cancel</Icon>
                      </MDButton>
                    </Grid>
                  }
                 
                </Grid>
              </MDBox >


              { !activeSearch ?
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                  </MDBox>
                    :
                    <MDBox>
                      
                        <DataTable
                          columns={columns}
                          data={data}
                          pagination
                          paginationServer
                          paginationTotalRows={totalRows}
                          paginationDefaultPage={currentPage}
                          paginationPerPage={rowsPerPage}
                          onChangePage={handlePageChange}
                          onChangeRowsPerPage={handleRowsPerPageChange}
                          paginationComponentOptions={{
                            rowsPerPageText: 'Colonias por página:',
                            rangeSeparatorText: 'de',
                            noRowsPerPage: false,
                            selectAllRowsItem: true,
                            selectAllRowsItemText: 'Todos'
                          }}
                          noDataComponent={<MDBox py={5}>No se encontraron datos.</MDBox>}
                        />
                    
                    </MDBox>
                  }
                </MDBox>
              </MDBox>
              :
              // Tabla de resultado de busqueda                
              <MDBox pt={3}>
                <MDBox px={3}>
                  {loading ? <MDBox display="block" fullWidth textAlign="center" p={5}>
                    <CircularProgress size="10rem" />
                    <MDTypography variant="h4" pt={3}>Buscando...</MDTypography>
                  </MDBox> :

                    <DataTable
                      pagination
                      columns={columns}
                      data={dataSearch}
                      progressPending={loading}
                      paginationComponentOptions={{
                        rowsPerPageText: 'Colonias por página:',
                        rangeSeparatorText: 'de',
                        noRowsPerPage: false,
                        selectAllRowsItem: true,
                        selectAllRowsItemText: 'Todos'
                      }}
                      noDataComponent={<MDBox py={5}>No se han encontrado resultados.</MDBox>}

                    />

                  }
                </MDBox>
              </MDBox>
            }
            </Card>
          </Collapse>
        </Fade>
      </Grid>
      <Grid container xs={12} md={12}>
        <Grid item pb={3} xs={12}>
          <Fade in={!show} timeout={800}>
            <Collapse in={!show} timeout={50}>
              <ColoniaForm data={selectedData}
                editar={editar}
                setShow={setShow}
                getLista={getData}
                coloniasArr={colonias} />
            </Collapse>
          </Fade>
        </Grid>
      </Grid>
      <Modal
        isOpen={modalIsOpen}
        contentLabel={`¿Seguro que desea ${selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
          } la colonia  ${selectedDelete ? selectedDelete.nombre : ""}?`}
        style={customStyles}
      >
        <Grid container p={1}>
          <Grid item md={12} sm={12}>
            <h2 className="alignCenter txtInfoModal">{`¿Seguro que desea ${selectedDelete && selectedDelete.deleted_at ? "activar" : "desactivar"
              } la colonia ${selectedDelete ? selectedDelete.nombre : ""}?`}</h2>
          </Grid>
        </Grid>
        <Grid container spacing={2} p={1}>
          <Grid item md={6} sm={12} xs={12} className="alignRigth">
            <MDButton className="btnPaymun" disabled={loading} onClick={() => deleteColonia()}>
              <Icon fontSize="small">done</Icon>
              &nbsp;{selectedDelete && selectedDelete.deleted_at ? "Activar" : "Desactivar"}
            </MDButton>
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="alignLeft">
            <MDButton className="btnBackPaymun" disabled={loading} onClick={() => closeModal()}>
              <Icon fontSize="small">arrow_back</Icon>
              &nbsp;Cancelar
            </MDButton>
          </Grid>
        </Grid>
      </Modal>
      <Footer />

    </Grid>
  );
}

// Typechecking props for the ProfileInfoCard
Colonias.propTypes = {
  action: PropTypes.shape({
    route: PropTypes.string.isRequired,
    tooltip: PropTypes.string.isRequired,
  }).isRequired
};
export default Colonias;