/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect, useRef } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from 'react-modal';
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Divider from "@mui/material/Divider";

import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import moment from 'moment';
import Icon from "@mui/material/Icon";
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { parseCurrency, selectStyles } from "tools/utils";
import PDFGenerator from "tools/PDFGenerator";
import PDFGeneratorCot from "tools/PDFGeneratorCot";
import PDFGeneratorCotPreview from "tools/PDFGeneratorCotPreview";

import { Get, Post, PostMulti } from "../../tools/Helper";
import Servicios from './Servicios';

import Message from "../../tools/Message";

function Tables({ type }) {
  const navigate = useNavigate();
  const scrollRef = useRef(React.createRef());
  const [inputKey, setInputKey] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [emailsCC, setEmailsCC] = useState('');
  const [checkDesc, setCheckDesc] = useState(false);
  const [enviandoEmail, setEnviandoEmail] = useState(false);
  const [regiones, setRegiones] = useState([]);
  const [responsablesRegion, setResponsablesRegion] = useState([]);
  const [responsablesCliente, setResponsablesCliente] = useState([]);
  const [requestData, setRequestData] = useState();
  const [msgModalEmail, setMsgModalEmail] = useState(`¿Desea enviar el correo con la ${type === 'cotizacion' ? 'Cotización' : 'OSS'}?`);

  const today = new Date();
  const zeroDate = today.getDate() < 9 ? "0" : "";
  const zeroMonth = today.getMonth() < 9 ? "0" : "";
  const datestring = `${today.getFullYear()}-${zeroMonth}${today.getMonth() + 1}-${zeroDate}${today.getDate()}`;
  const nombreVendedor = JSON.parse(localStorage.getItem("user")).user.name;

  const emptyForm = {
    numero: '0',
    num_cotizacion: '',
    fecha_cotizacion: datestring,
    fecha_oss: datestring,
    elabora: '',
    elabora_puesto: '',
    acepta: '',
    cliente: [],
    responsables: undefined,
    info_adicional: '',
    comentario: 'La presente orden de servicio y/o suministros (OSS) define implícitamente un deber para Paymun S.A. de C.V. de realizar/suministrar los servicios/productos descritos en el Apartado B, así mismo; implica el deber del Contratante (Apartado A) de realizar el pago correspondiente de acuerdo a los términos comerciales consentidos por ambas partes, en el entendido que al momento de emitir la presente OSS el contratante ha dado su consentimiento y autorización para realizar/suministrar los servicios/productos solicitados mediante la cotización referenciada.',
    direccion: '',
    estado: '',
    municipio: '',
    moneda: 'MXN',
    nombre_vendedor: '',
    usuario_ultimo: '',
    correo_vendedor: '',
    puesto_vendedor: '',
    texto_cotizacion: '',
    subtotal: 0,
    descuento: 0,
    iva: 0,
    total: 0,
    total_descuento: 0,
    status_cotizacion: type === 'cotizacion' ? 1 : 0,
    status_oss: type === 'cotizacion' ? 0 : 1,
    vigencia: 15,
    type,
    comentarios0: [],
    comentarios1: [],
    comentarios2: [],
    condiciones0: [],
    condiciones1: [],
    condiciones2: [],
    otra_condicion: '',
    otro_comentario: '',
    montoDescuento: 0,
    responsables_cliente: [],
  };

  const emptyServiciosArray = [{ id_servicio: '', cantidad: '', descripcion: '', servicio: [] }];
  const emptyCondicionesArray = [{ descripcion: '', servicio: [] }];
  const emptyComentariosArray = [{ descripcion: '', servicio: [] }];
  const [formData, setFormData] = useState(emptyForm);
  const animatedComponents = makeAnimated();
  const [clientes, setClientes] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [condiciones, setCondiciones] = useState([
    {
      comentarios: ''
    }
  ]);
  const [comentarios, setComentarios] = useState([
    {
      comentarios: ''
    }
  ]);
  const [responsables, setResponsables] = useState([{ nombre: '', puesto: '' }]);

  const [usuariosArray, setUsuariosArray] = useState([]);
  const [serviciosArray, setServiciosArray] = useState(emptyServiciosArray);
  const [condicionesArray, setCondicionesArray] = useState(emptyCondicionesArray);
  const [comentariosArray, setComentariosArray] = useState(emptyComentariosArray);

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState({ status: null, message: "" });
  const [statusEmail, setStatusEmail] = useState(false);

  const getData = async e => {

    setStatus({ status: 'warning', message: "Consultando, espere..." });
    setLoading(true);

    const request = await Get('catalogos');

    if (request.status === "success") {

      setClientes(request.clientes);
      setServicios(request.servicios);
      setResponsables(request.responsables);
      setCondiciones(request.condiciones_comerciales);
      setComentarios(request.comentarios_adicionales);
      setUsuariosArray(request.usuarios);

      const vendedor = request.usuarios.find(option => option.name === nombreVendedor);

      const {user} = JSON.parse(localStorage.getItem('user'));

      setFormData({
        ...formData,
        num_cotizacion: request.max_num_cot,
        numero: request.max_num_cot,
        nombre_vendedor: vendedor?.responsable?.nombre,
        usuario_ultimo: vendedor?.id,
        elabora_puesto: user.responsable?.puesto,
        elabora: user.name,
      })

      setStatus({ status: null, message: "" });
      setLoading(false);
    } else {
      setLoading(false);
      setStatus(request);
    }

  }

  const getSumPriceUnit = (data, descuento = null) => {
    const sumPUnit = data.reduce((acumulador, elemento) => (
      acumulador + (parseFloat(elemento.importe) * parseInt(elemento.cantidad, 10))
    ), 0);

    const totalDescuento = descuento ?? formData.descuento;
    const sumPUnitDescuento = parseFloat(sumPUnit) - (parseFloat(sumPUnit) * (parseFloat(totalDescuento) * 0.01));

    setFormData({
      ...formData,
      descuento: totalDescuento,
      subtotal: (sumPUnit).toFixed(2),
      montoDescuento: sumPUnitDescuento.toFixed(2),
      iva: (parseFloat((sumPUnitDescuento).toFixed(2)) * 0.16).toFixed(2),
      total: (parseFloat((sumPUnitDescuento).toFixed(2)) * 1.16).toFixed(2),
      total_descuento: (parseFloat((sumPUnitDescuento).toFixed(2)) * 1.16).toFixed(2),
    })
  };

  const setServArr = (data) => {
    setServiciosArray(data);
    getSumPriceUnit(data);
  }

  useEffect(() => {
    getData();  
  }, []);

  useEffect(() => {
    setFormData(emptyForm);
    setServiciosArray([]);
    setStatus({ status: null, message: "" });
  }, [type]);

  const getRegionsByCliente = async (idCliente) => {

    setStatus({ status: null, message: "" });    
    setRegiones([]);

    const request = await Get(`getRegionsByCliente/${idCliente}`);

    if (request.status === "success") {
      setRegiones(request.data);
      setStatus({ status: request.status, message: "Regiones cargadas exitosamente." });

      setTimeout(() => {
        setStatus({ status: null, message: "" });
      }, 2000);
    }
    else if (request.status === "info") {      
      setStatus({ status: "success", message: "No se encontraron regiones." });
    } else {      
      setStatus(request);
    }

  }

  const getResponsablesByRegion = async (idRegion) => {

    setStatus({ status: null, message: "" });
    setResponsablesRegion([]);
    setResponsablesCliente([]);

    const request = await Get(`getResponsablesByRegion/${idRegion}`);

    if (request.status === "success") {
      setResponsablesRegion(request.data);
      setStatus({ status: request.status, message: "Responsables cargados exitosamente." });
    }
    
  }

  const handleChange = (e, name = "", value = "") => {

    if (name === "") {
      if (e.target.name === 'descuento') {
        getSumPriceUnit(serviciosArray, e.target.value);
        return;
      }
      setFormData({ ...formData, [e.target.name]: e.target.value });
    } else {
      if (name === 'cliente') {

        const cliente = clientes.filter(item => item.id_cliente === parseInt(e[value], 10))[0];

        setFormData({
          ...formData,
          [value]: e[value],
          [name]: e,
          acepta: cliente.persona_contacto,
        });

        getRegionsByCliente(parseInt(e[value], 10));
        return;

      }
      if (name === 'nombre_vendedor') {
        const usuarioUltimo = usuariosArray.find(option => option.name === e.name)?.id;
        setFormData({
          ...formData,
          nombre_vendedor: usuariosArray.find(option => option.name === e.name).responsable?.nombre,
          usuario_ultimo: usuarioUltimo ?? '',
        });
        return;
      }

      if (name === 'id_region') {

        setFormData({
          ...formData,
          id_region: e[value]
        });

        getResponsablesByRegion(parseInt(e[value], 10));

        return;
      }

      if (name === 'responsablesCliente') {

        setResponsablesCliente(e);

        setFormData({
          ...formData,
          responsables_cliente: e
        });

        return;
      }

      setFormData({ ...formData, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const handleChangeEmail = (e, name = "", value = "") => {
    setEmailsCC(e.target.value);
  }

  const backToList = () => {

    if (type === 'cotizacion') {
      navigate('/cotizacion/lista-cotizacion')
    } else {
      navigate('/oss/lista-oss')
    }

  }

  const submitOss = async () => {

    const tempData = formData;
    tempData.servicios = serviciosArray;
    tempData.condiciones = condicionesArray;
    tempData.comentarios = comentariosArray;
    tempData.responsables_cliente = responsablesCliente;

    setStatus({ status: 'warning', message: "Guardando..." });
    setLoading(true);

    const request = await Post('oss', tempData);

    if (request.status === "success") {

      setRequestData(request.data);
      setIsOpen(true);
    }
    else {
      setLoading(false);
      setStatus(request);
    }

    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }

  }

  const getPreviewCot = async () => {
    const newData = formData;
    newData.servicios = serviciosArray;
    newData.condiciones = condicionesArray;
    newData.comentarios = comentariosArray;
    console.log(newData);
    const blob = await pdf(
      <PDFGeneratorCotPreview data={formData} />
    ).toBlob();

    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }

  const enviarEmails = async () => {

    setEnviandoEmail(true);
    setMsgModalEmail("Enviando correo...");
    setFormData(emptyForm);
    setInputKey(Math.random().toString(10));
    setServiciosArray(emptyServiciosArray);

    let request2 = '';

    let doc = <PDFGenerator data={requestData} />;
    let asPdf = pdf();
    asPdf.updateContainer(doc);
    let blob = await asPdf.toBlob();
    let formData2 = new FormData();
    formData2.append("file", blob);
    formData2.append("id_oss", requestData.id_oss);

    if (type === 'cotizacion') {
      doc = <PDFGeneratorCot data={requestData} />;
      asPdf = pdf();
      asPdf.updateContainer(doc);
      blob = await asPdf.toBlob();
      formData2 = new FormData();
      formData2.append("file", blob);
      formData2.append("id_oss", requestData.id_oss);
    }

    if (type === 'cotizacion') {
      request2 = await PostMulti('email-cotizacion', formData2);
    } else {
      request2 = await PostMulti('email', formData2);
    }

    setEmailsCC('');

    backToList();
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
    },
  };

  const getVendedor = usuariosArray.find(option => option.responsable?.nombre === formData.nombre_vendedor);
  const getRegion = regiones.find(option => option.id_region === formData.id_region);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={1} pb={3} ref={scrollRef}>

        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox p={3} className="headerFormPymn">
                <MDTypography variant="h4" color="white">
                  Capturar {type === 'cotizacion' ? 'Cotización' : 'OSS'}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <MDBox pb={3} px={3}>
                  <MDBox>
                    {loading ?

                      <MDBox display="block" textAlign="center" p={5}>
                        <CircularProgress size="10rem" />
                        <MDTypography variant="h4" pt={3}>Cargando...</MDTypography>
                      </MDBox>
                      :
                      <>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Message status={status} />
                          </Grid>
                          {
                            type === 'cotizacion' ? // COTIZACION
                              <>
                                <Grid item xs={4} mt="auto">
                                  <MDBox>
                                    <MDInput type="text" label="No. Cotización" name="num_cotizacion"
                                      error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                                      value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={4} mt="auto">
                                  <MDBox>
                                    <MDInput type="date" label="Fecha" name="fecha_cotizacion"
                                      error={verifyError("fecha_cotizacion")} errorMsg={getError("fecha_cotizacion")}
                                      value={moment(formData.fecha_cotizacion).locale('es').format('YYYY-MM-DD')} onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={12} md={4} mt="auto" style={{ zIndex: 9 }}>
                                  <MDInput type="number" label="Vigencia (días)" name="vigencia"
                                    error={verifyError("vigencia")} errorMsg={getError("vigencia")}
                                    value={formData.vigencia} onChange={handleChange} />
                                </Grid>
                              </>
                              :
                              <>
                                <Grid item xs={4} mt="auto">
                                  <MDBox>
                                    <MDInput type="text" label="No. Cotización" name="num_cotizacion"
                                      error={verifyError("num_cotizacion")} errorMsg={getError("num_cotizacion")}
                                      value={formData.num_cotizacion} onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={4} mt="auto">
                                  <MDBox>
                                    <MDInput type="date" label="Fecha" name="fecha_oss"
                                      error={verifyError("fecha_oss")} errorMsg={getError("fecha_oss")}
                                      value={formData.fecha_oss} onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>
                              </>
                          }

                          {
                            type !== 'cotizacion' ?
                              <Grid item xs={12}>
                                <MDBox>
                                  <MDInput type="text" label="Comentario" name="comentario"
                                    error={verifyError("comentario")} errorMsg={getError("comentario")}
                                    value={formData.comentario} onChange={handleChange}
                                    multiline rows={5} fullWidth />
                                </MDBox>
                              </Grid>
                              : null
                          }
                        </Grid>

                        <Divider />
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <MDBox mb={2}>
                              <MDTypography variant="h5" pb={1}>
                                Información de la empresa
                              </MDTypography>
                              <Select className={`z-10 ${verifyError("cliente") ? "border-red" : ""}`}
                                closeMenuOnSelect
                                options={clientes} placeholder="Empresa"
                                value={formData.cliente} onChange={(e) => handleChange(e, "cliente", "id_cliente")}
                                getOptionValue={(option) => `${option.id_cliente}`}
                                getOptionLabel={(option) => `${option.nombre_comercial}`}
                                menuPortalTarget={document.body} styles={selectStyles}
                              />
                              <small className="font-red">{getError("cliente")}</small>
                            </MDBox>
                          </Grid>


                          {(regiones.length > 0) ?
                            <>
                              <Grid item xs={12} md={6} mt="auto">
                                <Select
                                  className={`z-101 ${verifyError("id_region") ? "border-red" : ""}`}
                                  options={regiones} placeholder="Región"
                                  value={getRegion || ''}
                                  onChange={(e) => handleChange(e, "id_region", "id_region")}
                                  getOptionValue={(option) => `${option.id_region}`}
                                  getOptionLabel={(option) => `${option.nombre}`}
                                />
                              </Grid>

                              <Grid item xs={12} md={6} mt="auto">

                                <Select className={`z-10 ${verifyError("responsablesRegion") ? "border-red" : ""}`}
                                  placeholder="Responsables de empresa"
                                  closeMenuOnSelect
                                  components={animatedComponents}
                                  key={inputKey || ''}
                                  isMulti
                                  value={responsablesCliente} onChange={(e) => handleChange(e, "responsablesCliente", "respArray")}
                                  options={responsablesRegion}
                                  getOptionValue={(option) => `${option.id}`}
                                  getOptionLabel={(option) => `${option.nombre}`}
                                />

                              </Grid>
                            </>
                            : null
                          }

                        </Grid>
                        <Divider />
                        <Servicios serviciosArray={serviciosArray} setServiciosArray={setServArr}
                          catalogoServicios={servicios} errors={"errors" in status ? status.errors : null}
                          setStatus={setStatus} typeForm={type} getSumPriceUnit={getSumPriceUnit} editOss={formData.status_cotizacion === 0 ? 'true' : 'false'} />

                        {
                          type === 'cotizacion' ? // COTIZACION
                            <>
                              <Divider />
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4} mb={3}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="p" fontSize={18} pb={1}>
                                      ¿Descuento?
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDTypography variant="p" pb={1}>
                                      <input type="checkbox" checked={checkDesc}
                                        onChange={(e) => {
                                          setCheckDesc(!checkDesc)
                                          if (checkDesc) {
                                            setFormData({
                                              ...formData,
                                              descuento: 0,
                                            })
                                            getSumPriceUnit(serviciosArray, 0);
                                            return
                                          }

                                          getSumPriceUnit(serviciosArray, formData.descuento);
                                        }} />
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="p" fontSize={18} pb={1}>
                                      Subtotal
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDTypography variant="p" pb={1}>
                                      {parseCurrency(formData.subtotal)}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="p" fontSize={18} pb={1}>
                                      %Descuento
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDBox className="text-align-input">
                                      {
                                        checkDesc ?
                                          <MDInput
                                            className="text-align-input"
                                            type="number"
                                            label="" name="descuento"
                                            error={verifyError("descuento")}
                                            errorMsg={getError("descuento")}
                                            value={formData.descuento}
                                            onChange={handleChange} />
                                          : null
                                      }
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="p" fontSize={18} pb={1}>
                                      Subtotal Desc.
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDTypography variant="p" pb={1}>
                                      {parseCurrency(formData.montoDescuento)}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="p" pb={1}>
                                      I.V.A.
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDTypography variant="p" pb={1}>
                                      {parseCurrency(formData.iva)}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid xs={6} md={7} />
                                <Grid container item spacing={2} xs={6} md={4}>
                                  <Grid xs={1} md={2} />
                                  <Grid item xs={5} md={5}>
                                    <MDTypography variant="h5" pb={1}>
                                      Total a pagar
                                    </MDTypography>
                                  </Grid>
                                  <Grid item xs={6} md={5} style={{ textAlign: 'right' }} >
                                    <MDTypography variant="h5" pb={1}  >
                                      {parseCurrency(formData.total_descuento)}
                                    </MDTypography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid>
                                <Grid container>
                                  <Grid item md={6} style={{ zIndex: 20 }}>
                                    <MDBox mb={2}>
                                      <MDTypography variant="h5" fontSize={14} pb={0} >
                                        Vendedor
                                      </MDTypography>
                                      <Select className={`z-10 ${verifyError("nombre_vendedor") ? "border-red" : ""}`}
                                        options={usuariosArray} placeholder="Vendedor..."
                                        value={getVendedor} onChange={(e) => handleChange(e, "nombre_vendedor")}
                                        getOptionValue={(option) => `${option.responsable?.nombre}`}
                                        getOptionLabel={(option) => `${option.responsable?.nombre}`}
                                      />
                                      <small className="font-red">{getError("nombre_vendedor")}</small>
                                    </MDBox>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item xs={12} style={{ zIndex: 10 }}>
                                    <MDBox mb={2}>
                                      <MDTypography variant="h5" fontSize={14} pb={0} >
                                        Comentarios adicionales
                                      </MDTypography>
                                      {
                                        comentarios.map((item, index) => (
                                          <Grid mt={1}>
                                            <Select className={`font-size-select ${verifyError("comentarios_adicionales") ? "border-red" : ""}`}
                                              options={comentarios[index].comentarios} placeholder={`Seleccione ${comentarios[index].descripcion}`}
                                              value={formData[`comentarios${index}`]} onChange={(e) => handleChange(e, `comentarios${index}`, "id_comentario_adicional")}
                                              getOptionValue={(option) => `${option.id_comentario_adicional}`}
                                              getOptionLabel={(option) => `${option.descripcion}`}
                                            />
                                          </Grid>
                                        ))
                                      }
                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={12} mb={2}>
                                    <MDBox>
                                      <MDInput multiline rows={5} type="text" label="Información adicional" name="info_adicional"
                                        error={verifyError("info_adicional")} errorMsg={getError("info_adicional")}
                                        value={formData.info_adicional} onChange={handleChange} fullWidth />
                                    </MDBox>
                                  </Grid>
                                </Grid>
                                <Grid container>
                                  <Grid item xs={12} style={{ zIndex: 9 }}>
                                    <MDBox mb={2}>
                                      <MDTypography variant="h5" xs={12} fontSize={14} pb={0} >
                                        Condiciones comerciales
                                      </MDTypography>
                                      {
                                        condiciones.map((item, index) => (
                                          <Grid mt={1}>
                                            <Select className={`font-size-select ${verifyError("condiciones_comerciales") ? "border-red" : ""}`}
                                              options={condiciones[index].condiciones} placeholder={`Seleccione ${condiciones[index].descripcion}`}
                                              value={formData[`condiciones${index}`]} onChange={(e) => handleChange(e, `condiciones${index}`, "id_condicion_comercial")}
                                              getOptionValue={(option) => `${option.id_condicion_comercial}`}
                                              getOptionLabel={(option) => `${option.descripcion}`}
                                            />
                                          </Grid>
                                        ))
                                      }

                                    </MDBox>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <MDBox>
                                      <MDInput multiline rows={5} type="text" label="Agregue alguna otra condición comercial que considere necesaria" name="otra_condicion"
                                        error={verifyError("otra_condicion")} errorMsg={getError("otra_condicion")}
                                        value={formData.otra_condicion} onChange={handleChange} fullWidth />
                                    </MDBox>
                                  </Grid>
                                </Grid>

                              </Grid>
                            </>
                            :
                            <>
                              <Grid item xs={12}>
                                <MDBox mb={2}>
                                  <MDInput type="text" label="Información Adicional" name="info_adicional"
                                    error={verifyError("info_adicional")} errorMsg={getError("info_adicional")}
                                    value={formData.info_adicional} onChange={handleChange}
                                    multiline rows={5} fullWidth />
                                </MDBox>
                              </Grid>
                              <Divider />
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <MDBox mb={2}>
                                    <MDTypography variant="h5" pb={1}>
                                      Responsables {type === 'cotizacion' ? 'Cotización' : 'OSS'}
                                    </MDTypography>
                                    <Select className={`z-10 ${verifyError("responsables") ? "border-red" : ""}`}
                                      closeMenuOnSelect
                                      placeholder="Responsables"
                                      components={animatedComponents}
                                      key={inputKey || ''}
                                      isMulti
                                      value={formData.responsables} onChange={(e) => handleChange(e, "responsables", "respArray")}
                                      options={responsables}
                                      getOptionValue={(option) => `${option.id_responsable}`}
                                      getOptionLabel={(option) => `${option.nombre} - ${option.puesto}`}
                                    />
                                    <small className="font-red">{getError("responsables")}</small>
                                  </MDBox>
                                </Grid>
                              </Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <MDBox>
                                    <MDTypography variant="h5" pb={1}>
                                      Firma de Autorizacion
                                    </MDTypography>
                                    <MDInput 
                                      type="text" 
                                      label="Nombre de quien elabora" 
                                      name="elabora"
                                      error={verifyError("elabora")} 
                                      errorMsg={getError("elabora")}
                                      inputProps={{ disabled: true, }}
                                      value={formData.elabora} 
                                      onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>
                                <Grid item xs={6} mt="auto">
                                  <MDBox>
                                    <MDInput 
                                      type="text" 
                                      label="Puesto" name="elabora_puesto"
                                      error={verifyError("elabora_puesto")} 
                                      errorMsg={getError("elabora_puesto")}
                                      value={formData.elabora_puesto} 
                                      inputProps={{ disabled: true, }}
                                      onChange={handleChange} fullWidth />
                                  </MDBox>
                                </Grid>

                                <Grid item xs={12} mt="auto">
                                  <MDBox mb={2}>
                                    <MDInput type="text" label="Escriba los correos a quien se enviará copia separados por coma." name="emails_cc"
                                      error={verifyError("emails_cc")} errorMsg={getError("emails_cc")}
                                      value={emailsCC} onChange={handleChangeEmail}
                                      multiline fullWidth />
                                    {
                                      statusEmail ?
                                        <p style={{ color: 'red', fontSize: 12, padding: 5 }}>Debe escribir al menos un correo válido</p>
                                        : null
                                    }
                                  </MDBox>
                                </Grid>                                
                              </Grid>
                            </>
                        }

                        <Grid container mt={1} spacing={2}>
                          {
                            type === 'cotizacion' ?
                              <Grid item md={4}>
                                <MDBox item mt={4} mb={1} >
                                  <MDButton variant="gradient" color="warning"
                                    onClick={() => getPreviewCot()} fullWidth>
                                    Vista previa
                                  </MDButton>
                                </MDBox>
                              </Grid>
                              : null
                          }

                          <Grid item xs={12} md={4}>
                            &nbsp;
                          </Grid>

                          <Grid item xs={6} md={2}>

                            <MDButton
                              disabled={loading}
                              className="btnPaymun"
                              onClick={() => submitOss()} fullWidth>
                              <Icon fontSize="small">done</Icon>
                              &nbsp;Guardar
                            </MDButton>
                          </Grid>

                          <Grid item xs={6} md={2}>
                            <MDButton
                              className="btnBackPaymun"
                              fullWidth
                              onClick={() => backToList(true)}>
                              <Icon fontSize="small">arrow_back</Icon>
                              &nbsp;Regresar
                            </MDButton>
                          </Grid>

                        </Grid>
                      </>
                    }
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Modal
        isOpen={modalIsOpen}
        contentLabel=""
        style={customStyles}
      >

        <Grid container spacing={2} p={1}>
          <Grid item xs={12}>
            <h5>{msgModalEmail}</h5>
          </Grid>

          <Grid item xs={2} mt={1}>
            &nbsp;
          </Grid>

          <Grid item xs={4} mt={1}>
            <MDButton
              className="btnPaymun"
              disabled={enviandoEmail}
              onClick={() => enviarEmails()}
              fullWidth>
              <Icon fontSize="small">mail_outline</Icon>
              &nbsp;Aceptar
            </MDButton>
          </Grid>
          <Grid item xs={4} mt={1}>
            <MDButton
              className="btnBackPaymun"
              disabled={enviandoEmail}
              onClick={() => backToList()}
              fullWidth>
              <Icon fontSize="small">arrow_back</Icon>
              &nbsp;Regresar
            </MDButton>
          </Grid>
        </Grid>
      </Modal>

      <Divider />
      <Footer />
    </DashboardLayout>
  );
}

Tables.propTypes = {
  type: PropTypes.bool.isRequired,
};

export default Tables;
