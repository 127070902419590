import React, { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Select from 'react-select';
import PropTypes from 'prop-types';

import Message from "../../tools/Message";
import { Get } from "../../tools/Helper";

function Direccion({ editing, dataCursoProgramado, setDataCursoProgramado }) {

  const [status, setStatus] = useState({ status: null, message: "" });
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [regiones, setRegiones] = useState([]);
  const [region, setRegion] = useState([]);

  const getSucursal = sucursales.find(option => option.id_sucursal === dataCursoProgramado.id_sucursal);
  const getRegion = regiones.find(option => option.id_region === dataCursoProgramado.id_region);

  const handleChange = (e, name = "", value = "") => {
    if (name === "") {
      setDataCursoProgramado({ ...dataCursoProgramado, [e.target.name]: e.target.value });
    } else {
      setDataCursoProgramado({ ...dataCursoProgramado, [value]: e[value], [name]: e });
    }

    if ("errors" in status) {
      const verifyName = name === "" ? e.target.name : name;
      if (verifyName in status.errors) {
        const temp = status;
        delete temp.errors[verifyName];
        setStatus(temp);
      }
    }
  }

  const verifyError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return true;
      }
    }

    return false;
  }

  const getError = (name) => {
    if ("errors" in status) {
      if (name in status.errors) {
        return status.errors[name][0];
      }
    }

    return "";
  }

  const getRegionsByCliente = async () => {

    setStatus({ status: null, message: "" });
    setLoading(true);
    setRegiones([]);

    const request = await Get(`getRegionsByCliente/${dataCursoProgramado.id_cliente}`);

    if (request.status === "success") {
      setRegiones(request.data);
      setStatus({ status: request.status, message: "Regiones cargadas exitosamente." });
    }

    setLoading(false);
  }

  const getSucursalesByRegion = async (idRegion) => {

    if (idRegion) {

      setStatus({ status: null, message: "" });
      setLoading(true);
      setSucursales([]);

      const request = await Get(`getSucursalesByRegion/${idRegion}`);

      if (request.status === "success") {
        setSucursales(request.data);

        setStatus({ status: request.status, message: "Sucursales cargadas exitosamente." });
      } else if (request.status === "empty") {
        setStatus({ status: "info", message: "No se encontraron Sucursales, capture la dirección por favor." });
      }

      setLoading(false);
    }

  }

  useEffect(async () => {

    await getRegionsByCliente();

    if (dataCursoProgramado.id_region !== 0) {
      await getSucursalesByRegion(dataCursoProgramado.id_region);
    }

  }, [dataCursoProgramado.id_cliente]);

  const handleChangeSelect = (e, name = "", value = "") => {

    if (value === 'id_region') {

      const infoRegion = regiones.find(option => option.id_region === e[name]);

      setDataCursoProgramado({
        ...dataCursoProgramado,
        calle_numero: '',
        colonia: '',
        municipio_alcaldia: '',
        estado: '',
        id_sucursal: 0,
        id_region: e[name],
        region: infoRegion,
      });

      getSucursalesByRegion(e[name]);

    } else if (value === 'id_sucursal') {

      const infoSuc = sucursales.find(option => option.id_sucursal === e[name]);

      setDataCursoProgramado({
        ...dataCursoProgramado,
        calle_numero: infoSuc.calle_numero,
        colonia: infoSuc.colonia,
        municipio_alcaldia: infoSuc.municipio,
        estado: infoSuc.estado,
        id_sucursal: e[name],
      });


    }
  }

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Message status={status} />
        </Grid>

        {(regiones.length > 0) ?
          <>
            <Grid item xs={12} md={6} mt="auto">
              <MDTypography variant="caption" pt={3} pr={3}>Región:</MDTypography>
              <Select
                className={`z-101 ${verifyError("id_region") ? "border-red" : ""}`}
                options={regiones} placeholder="Región"
                value={dataCursoProgramado.region || ''}
                onChange={(e) => handleChangeSelect(e, "id_region", "id_region")}
                getOptionValue={(option) => `${option.id_region}`}
                getOptionLabel={(option) => `${option.nombre}`}
                isDisabled={!editing}
              />
            </Grid>
            <Grid item xs={12} md={6} mt="auto">
            <MDTypography variant="caption" pt={3} pr={3}>Sucursal:</MDTypography>            
              <Select
                className={`z-101 ${verifyError("id_sucursal") ? "border-red" : ""}`}
                options={sucursales} placeholder="Sucursal"
                value={getSucursal || ''}
                onChange={(e) => handleChangeSelect(e, "id_sucursal", "id_sucursal")}
                getOptionValue={(option) => `${option.id_sucursal}`}
                getOptionLabel={(option) => `${option.nombre}`}
                isDisabled={!editing}
              />
            </Grid>
          </>
          : null
        }

        <Grid item xs={12} md={6} mt="auto">
          <MDInput type="text" label="Calle y Número" name="calle_numero"
            error={verifyError("calle_numero")} errorMsg={getError("calle_numero")}
            value={dataCursoProgramado.calle_numero || ''} onChange={handleChange} fullWidth
            disabled={!editing}
          />
        </Grid>
        <Grid item xs={12} md={6} mt="auto">
          <MDInput type="text" label="Colonia" name="colonia"
            error={verifyError("colonia")} errorMsg={getError("colonia")}
            value={dataCursoProgramado.colonia || ''} onChange={handleChange} fullWidth
            disabled={!editing}
          />
        </Grid>
        <Grid item xs={12} md={6} mt="auto">
          <MDInput type="text" label="Municipio/Alcaldía" name="municipio_alcaldia"
            error={verifyError("municipio_alcaldia")} errorMsg={getError("municipio_alcaldia")}
            value={dataCursoProgramado.municipio_alcaldia || ''} onChange={handleChange} fullWidth
            disabled={!editing}
          />
        </Grid>
        <Grid item xs={12} md={6} mt="auto">
          <MDInput type="text" label="Estado" name="estado"
            error={verifyError("estado")} errorMsg={getError("estado")}
            value={dataCursoProgramado.estado || ''} onChange={handleChange} fullWidth
            disabled={!editing}
          />
        </Grid>

      </Grid>
    </MDBox>
  );

}

Direccion.propTypes = {
  dataCursoProgramado: PropTypes.shape({
    id_cliente: PropTypes.number,
    id_region: PropTypes.number,
    id_sucursal: PropTypes.number,
    calle_numero: PropTypes.string,
    colonia: PropTypes.string,
    municipio_alcaldia: PropTypes.string,
    estado: PropTypes.string,
    latitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    longitude: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    region: PropTypes.string,
  }).isRequired,
  editing: PropTypes.bool.isRequired,
  setDataCursoProgramado: PropTypes.func.isRequired,
};

export default Direccion;
