import React, { useState, useEffect, } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import PropTypes from 'prop-types';
import Grid from "@mui/material/Grid";
import { tileLayer } from "./utils"

function LocationMarker({ onMove, pos, setUbicacion }) {

  useEffect(() => {  
  }, [ pos]);

  return (
    <Marker
      position={pos}
      draggable
      autoPan
      eventHandlers={{
        moveend: (event) => {
          setUbicacion([event.target.getLatLng().lat, event.target.getLatLng().lng]);
          onMove([event.target.getLatLng().lat, event.target.getLatLng().lng]);
        }
      }}
    />
  );
}

LocationMarker.propTypes = {
  pos: PropTypes.bool.isRequired,
  onMove: PropTypes.func.isRequired,
  setUbicacion: PropTypes.func.isRequired,
};

// Used to recenter the map to new coordinates
function MapRecenter({ pos }) {
  const map = useMap();

  useEffect(() => {
    // Fly to that coordinates and set new zoom level
    map.flyTo(pos, 13);
  }, [pos]);

  return null;
}

MapRecenter.propTypes = {
  pos: PropTypes.bool.isRequired,
};

function MapLocation({ setUbicacion, ubicacion}) {

  const [markerPos, setMarkerPos] = useState(ubicacion);  

  useEffect(() => {
    
    setMarkerPos(ubicacion);   
    
    }, [ ubicacion]);

  return (
    <Grid container pt={2}>

      <Grid item height={400} xs={12} mt="auto"  >

        <MapContainer
          center={markerPos}
          zoom={13}
          bounceAtZoomLimits={false}
          style={{ height: "400px", width: "100%" }}
          >

          <TileLayer {...tileLayer} />

          <MapRecenter pos={markerPos} zoomLevel={13} />
          <LocationMarker onMove={setMarkerPos} pos={markerPos} setUbicacion={setUbicacion} />
        </MapContainer>
      </Grid>

    </Grid>
  );
}

MapLocation.propTypes = {
  setUbicacion: PropTypes.func.isRequired,
  ubicacion : PropTypes.string.isRequired,
  
};
export default MapLocation;
